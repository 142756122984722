import { apps } from '@kireidy/configuration';

export const environment = {
    production: false,
    apps,

    sentry: {
        url: 'https://morgan-wild.sentry.io/projects/'
    },
};
