<div class="header" wuiTacticalCorners [wuiTacticalCornersOptions]="{ disappear: false }">

    <div class="title">
        <div>KIREIDY BACKOFFICE</div>
        <div class="host">
            {{ host ? host.name : '' | uppercase }}
        </div>
    </div>

    <div class="logout" (click)="logout()">
        {{ onlineService.tokenPayload?.name }}
    </div>

    <div class="screen" (click)="toggleFullscreen()" title="enter / leave fullscreen"> ⛶ </div>

</div>

<div class="body">

    <div class="menu" wuiTacticalCorners [wuiTacticalCornersOptions]="{ disappear: false }">
        <div class="button" routerLinkActive="active" [routerLink]="'dashboard'">DASHBOARD</div>
        <div class="button" routerLinkActive="active" [routerLink]="'events'">EVENTS</div>
        <div class="separator"></div>
        <div class="button" routerLinkActive="active" [routerLink]="'state'">STATE</div>
        <div class="separator"></div>
        <div class="button" routerLinkActive="active" [routerLink]="'accounts'">ACCOUNTS</div>
    </div>

    <div class="content" wuiTacticalCorners [wuiTacticalCornersOptions]="{ disappear: false }">
        <router-outlet></router-outlet>
    </div>

</div>

