import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountRoles } from '@kireidy/definitions';
import { LoginComponent } from './login/login/login.component';
import { AuthenticationGuard } from './shared/guards/authentication/authentication.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./features/features.module').then(modules => modules.FeaturesModule),
        canActivate: [ AuthenticationGuard ],
        data: { role: AccountRoles.Admininstrator }
    },
    {
        path: 'login',
        component: LoginComponent
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
