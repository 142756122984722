import { Component, Input } from '@angular/core';
import { Account, AccountStatus } from '@kireidy/definitions';
import { OnlineService } from '@kireidy/services';
import { OverlayService } from '@kireidy/wild-ui';

@Component({
    selector: 'app-accounts-edit',
    templateUrl: './accounts-edit.component.html',
    styleUrls: ['./accounts-edit.component.scss'],
})
export class AccountsEditComponent {

    public AccountStatus = AccountStatus;

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public resolve = (value: Account | null = null) => Function;

    @Input()
    public title = '';

    @Input()
    public account = new Account();

    public constructor(private overlayService: OverlayService, private onlineService: OnlineService) {}

    public cancel(): void {

        this.resolve(null);

        this.overlayService.closeModal();
    }
}
