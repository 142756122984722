import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { OnlineService } from '@kireidy/services';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationGuard  {

    constructor(private router: Router, private onlineService: OnlineService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        // Valid account
        if (this.onlineService.isTokenValid()) {

            const requiredRole = route.data['role'];

            // No specific role required
            if (requiredRole === undefined) {

                return true;

            // Specific role required
            } else if (this.onlineService.tokenPayload?.roles.includes(requiredRole)) {

                return true;
            }
        }

        this.router.navigate(['login']);

        return false;

    }

}
