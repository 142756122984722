import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsComponent } from './components/events/events.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
    declarations: [
        EventsComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
    ]
})
export class EventsModule { }
