import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';

import { XEvent, XEventsSearchRequest, XEventsSearchResponse } from '@kireidy/definitions';
import { OnlineService } from '@kireidy/services';
import { KeyValuePipe, OverlayService } from '@kireidy/wild-ui';
import { AppsUtils } from '@kireidy/configuration';

import { ModalConfirmComponent } from 'apps/backoffice/src/app/shared/components/modal-confirm/modal-confirm.component';
import { ModalTableFilterComponent } from 'apps/backoffice/src/app/shared/components/modal-table-filter/modal-table-filter.component';
import { FiltersOptions } from 'apps/backoffice/src/app/shared/models/table-filters-options.types';
import { TableLine } from 'apps/backoffice/src/app/shared/models/table-line.types';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

    public lines: Array<TableLine<XEvent>> = [];

    public filters: { [filter: string]: string } = {};

    private isLoading = false;

    private frontendUrl = AppsUtils.resolveAppUrl('frontend');

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private onlineService: OnlineService,
        private keyValuePipe: KeyValuePipe) { }

    ngOnInit(): void {

        this.filters = structuredClone(this.route.snapshot.queryParams);

        this.load(false);
    }

    public load(next: boolean): void {

        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        const request: XEventsSearchRequest = {
            index: next ? this.lines.length : 0,
            limit: 50,
            ...this.filters
        }

        this.onlineService
            .searchEvents(request)
            .pipe(finalize(() => { this.isLoading = false; }))
            .subscribe({
                next: (response: XEventsSearchResponse) => {

                    const lines: Array<TableLine<XEvent>> = response.items.map((item) => {
                        return {
                            isSelected: false,
                            data: item,
                            computed: {
                                isDatePassed: item.date.getTime() < Date.now()
                            }
                        }
                    });

                    this.lines = next ? this.lines.concat(lines) : lines;
                }
            });
    }

    public async searchBy(filter: string) {

        const options: FiltersOptions = {
            description: 'search by ' + filter,
            type: 'text',
            choices: [],
            value: this.filters[filter]
        };

        switch(filter) {

            case 'date':
                options.type = 'date';
                options.value = new Date().toISOString().slice(0, 10);
            break;
        }

        // Selection modal
        const value: string | undefined = await this.overlayService.openModal({
            component: ModalTableFilterComponent,
            inputs: {
                options
            }
        });

        // Canceled
        if(value === undefined) {
            return;
        }

        if(value === '') {
            delete this.filters[filter];
        } else {
            this.filters[filter] = value;
        }

        this.load(false);

        // Update the url according the filter
        this.router.navigate([], { queryParams: this.filters });
    }

    public view(event: XEvent): void {

        window.open(`${this.frontendUrl}/events/${event.id}?skip=true`);
    }

    public edit(event: XEvent): void {

        window.open(`${this.frontendUrl}/events/${event.id}/edition/${event.key}`);
    }

    public async delete(event: XEvent): Promise<void> {

        if (await this.overlayService.openModal({
            component: ModalConfirmComponent,
            inputs: {
                description: `Are you sure you want to remove the event ${event.name}?`
            }
        }) !== true) {

            return;
        }

        this.onlineService.deleteEvent(event.id, { key: event.key }).subscribe({
            next: () => {
                this.lines = this.lines.filter(line => line.data.id !== event.id);
            }
        });

    }






    // ------------------------------------------------------------------
    // TODO: MOVE IT INTO AN UTILS FILE


    public selectAll(event: any): void {

        for (const line of this.lines) {
            line.isSelected = event.target.checked;
        }
    }

    public select(line: TableLine<XEvent>, event: MouseEvent): void {

        line.isSelected = !line.isSelected;

        if(event.shiftKey || event.ctrlKey) {

            const indexClicked = this.lines.indexOf(line);

            if (indexClicked > 0) {

                let indexTop: number | undefined;

                for(let i = indexClicked - 1; i >= 0; i--) {
                    if(this.lines[i].isSelected === line.isSelected) {
                        indexTop = i;
                        break;
                    }
                }

                if (indexTop !== undefined) {

                    for(let i = indexClicked; i > indexTop; i--) {
                        this.lines[i].isSelected = line.isSelected;
                    }

                    return;
                }
            }

            if (indexClicked < this.lines.length) {

                let indexBottom: number | undefined;

                for(let i = indexClicked + 1; i <= this.lines.length; i++) {
                    if(this.lines[i].isSelected === line.isSelected) {
                        indexBottom = i;
                        break;
                    }
                }

                if (indexBottom !== undefined) {

                    for(let i = indexClicked; i < indexBottom; i++) {
                        this.lines[i].isSelected = line.isSelected;
                    }

                    return;
                }

            }

        }
    }


}
