<div class="layout vertical scrollable" (wuiScrolledBottom)="load(true)" [wuiScrolledBottomThreshold]="100">

    <table>

        <tr class="header">

            <!-- <td>
                <input type="checkbox" (change)="selectAll($event)">
            </td> -->

            <td>n. </td>

            <td [class.active]="filters['id'] !== undefined">
                id
                <span
                    (click)="searchBy('id')">
                    🔎
                </span>
            </td>

            <td [class.active]="filters['email'] !== undefined">
                administrator's email
                <span
                    (click)="searchBy('email')">
                    🔎
                </span>
            </td>

            <td [class.active]="filters['name'] !== undefined">
                name
                <span
                    (click)="searchBy('name')">
                    🔎
                </span>
            </td>

            <td>
                subscriptions
            </td>

            <td [class.active]="filters['date'] !== undefined">
                date
                <span
                    (click)="searchBy('date')">
                    🔎
                </span>
            </td>

            <td>
                actions
            </td>
        </tr>

        @for (line of lines; track line; let i = $index) {

            <tr [class.date-passed]="line.computed.isDatePassed" (click)="view(line.data)">

                <!-- <td>
                    <input type="checkbox" [checked]="line.isSelected" (click)="select(line, $event)">
                </td> -->

                <td>{{ i + 1 }}</td>

                <td>{{ line.data.id }}</td>

                <td>{{ line.data.email }}</td>

                <td>{{ line.data.name }}</td>

                <td>

                    {{ line.data.members.length }}

                    @if(line.data.limit) {
                        / {{ line.data.limit }}
                    }

                </td>

                <td>{{ line.data.date | date: 'YYYY MM dd' }}</td>

                <td class="actions" wuiStopPropagation>
                    <div
                        title="edit"
                        (click)="edit(line.data)">
                        🖋️
                    </div>
                    <div
                        class="delete"
                        title="delete"
                        (click)="delete(line.data)">
                        🗙
                    </div>
                </td>

            </tr>
        }


    </table>

</div>
