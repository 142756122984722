import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { FeaturesRoutingModule } from './features-routing.module';

import { FeaturesComponent } from './features.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { SharedModule } from '../shared/shared.module';
import { EventsModule } from './events/events.module';
import { StateModule } from './state/state.module';
import { AccountsModule } from './accounts/accounts.module';

@NgModule({
    declarations: [
        FeaturesComponent,
    ],
    imports: [
        CommonModule,
        FeaturesRoutingModule,
        SharedModule,
        DashboardModule,
        EventsModule,
        StateModule,
        AccountsModule
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class FeaturesModule { }
