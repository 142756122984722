import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AccountJWTPayload, AccountRoles, LoginRequest } from '@kireidy/definitions';
import { OnlineService } from '@kireidy/services';
import { OverlayService } from '@kireidy/wild-ui';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {

    public request: LoginRequest = {
        email: '',
        password: ''
    }

    constructor(
        private router: Router,
        private overlayService: OverlayService,
        private onlineService: OnlineService) { }

    public get isInvalid(): boolean {

        return !this.request.email || !this.request.password;
    }

    public login(): void {

        this.onlineService.login(this.request).subscribe({
            next: (account: AccountJWTPayload | undefined) => {

                if (account?.roles.includes(AccountRoles.Admininstrator) === false) {

                    this.overlayService.openNotification({
                        message: 'This interface is strictly reserved to the administrators',
                        type: 'error'
                    });

                    return;
                }

                this.router.navigate(['']);
            }
        })
    }

}
