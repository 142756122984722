import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateModule } from '@ngx-translate/core';

import { ErrorsInterceptor, /* KireidyServicesModule*/  } from '@kireidy/services';
import { OverlayService, StyleUtils, WildUiModule } from '@kireidy/wild-ui';

import { ModalTableFilterComponent } from './components/modal-table-filter/modal-table-filter.component';
import { HeaderComponent } from './components/header/header.component';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';

@NgModule({
    declarations: [
        HeaderComponent,
        ModalTableFilterComponent,
        ModalConfirmComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        WildUiModule,
        // KireidyServicesModule,
    ],
    exports: [
        FormsModule,
        TranslateModule,
        WildUiModule,

        HeaderComponent,
        ModalTableFilterComponent,
        ModalConfirmComponent,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorsInterceptor,
            multi: true,
        },
    ],
})
export class SharedModule {
    constructor(overlayService: OverlayService) {

        // Register notifications styles
        overlayService.notificationsStyles = [
            {
                type: 'success',
                backgroundColor: StyleUtils.getVariable('--color-background-success'),
                foregroundColor: StyleUtils.getVariable('--color-text-success'),
            },
            {
                type: 'warning',
                backgroundColor: StyleUtils.getVariable('--color-background-warning'),
                foregroundColor: StyleUtils.getVariable('--color-text-warning'),
            },
            {
                type: 'error',
                backgroundColor: StyleUtils.getVariable('--color-background-error'),
                foregroundColor: StyleUtils.getVariable('--color-text-error'),
            },
        ];
    }
}
