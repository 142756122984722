import { Component, Input, OnInit } from '@angular/core';
import { OverlayService } from '@kireidy/wild-ui';
import { FiltersOptions } from '../../models/table-filters-options.types';

@Component({
    selector: 'app-modal-table-filter',
    templateUrl: './modal-table-filter.component.html',
    styleUrls: ['./modal-table-filter.component.scss']
})
export class ModalTableFilterComponent implements OnInit {

    @Input()
    public options: FiltersOptions = {
        description: '',
        type: '',
        choices: [],
        value: '',
    }

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    public resolve = (value: string | null = null) => { };


    constructor(private overlayService: OverlayService) { }

    ngOnInit(): void {

        console.log(this.options);
    }

    public clear(): void {

        this.resolve('');

        this.overlayService.closeModal();
    }

    public confirm(): void {

        this.resolve(this.options.value);

        this.overlayService.closeModal();
    }

}
