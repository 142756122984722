<div class="layout vertical scrollable" (wuiScrolledBottom)="load(true)" [wuiScrolledBottomThreshold]="100">

    <table>

        <tr class="header">

            <!-- <td>
                <input type="checkbox" (change)="selectAll($event)">
            </td> -->

            <td>n. </td>

            <td [class.active]="filters['id'] !== undefined">
                id
                <span
                    (click)="searchBy('id')">
                    🔎
                </span>
            </td>

            <td [class.active]="filters['email'] !== undefined">
                email
                <span
                    (click)="searchBy('email')">
                    🔎
                </span>
            </td>

            <td [class.active]="filters['name'] !== undefined">
                name
                <span
                    (click)="searchBy('name')">
                    🔎
                </span>
            </td>

            <td [class.active]="filters['roles'] !== undefined">
                roles
                <span
                    (click)="searchBy('roles')">
                    🔎
                </span>
            </td>

            <td [class.active]="filters['status'] !== undefined">
                status
                <span
                    (click)="searchBy('status')">
                    🔎
                </span>
            </td>

            <!-- <td>
                actions
            </td> -->
        </tr>

        @for (line of lines; track line; let i = $index) {

            <tr (click)="edit(line.data)">

                <!-- <td>
                    <input type="checkbox" [checked]="line.isSelected" (click)="select(line, $event)">
                </td> -->

                <td>{{ i + 1 }}</td>

                <td>{{ line.data.id }}</td>

                <td>{{ line.data.email }}</td>

                <td>{{ line.data.name }}</td>

                <td>{{ line.data.roles }}</td>

                <td [style.color]="'var(--color-accounts-status-' + line.data.status + ')'">{{ line.data.status }}</td> <!-- account status css to define -->

                <!-- <td wuiStopPropagation>
                    X
                </td> -->

            </tr>
        }

    </table>

</div>
