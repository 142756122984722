import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Dashboard } from '@kireidy/definitions';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    public dashboard = new Dashboard();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {


        this.activatedRoute.data.subscribe({
            next: ({ dashboard }) => {

                if (!dashboard) {
                    return;
                }

                this.dashboard = dashboard;
            }
        });

    }

}
