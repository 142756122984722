import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';

import { RouteReuseStrategyReload } from '../app-route-reuse-strategy';

import { FeaturesComponent } from './features.component';

import { DashboardComponent } from './dashboard/components/dashboard/dashboard.component';
import { DashboardResolver } from './dashboard/resolvers/dashboard/dashboard.resolver';

import { EventsComponent } from './events/components/events/events.component';
import { StateComponent } from './state/components/state/state.component';
import { AccountsComponent } from './accounts/components/accounts/accounts.component';

const routes: Routes = [
    {
        path: '',
        component: FeaturesComponent, // Routes for the router outlet of the features component
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'dashboard', component: DashboardComponent, resolve: { dashboard: DashboardResolver } },
            { path: 'events', component: EventsComponent },
            { path: 'state', component: StateComponent },
            { path: 'accounts', component: AccountsComponent },
        ]
    }
];


@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: RouteReuseStrategyReload
        }
    ]
})
export class FeaturesRoutingModule { }

