import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OverlayService } from '@kireidy/wild-ui';
import { Host } from '@kireidy/definitions';
import { OnlineService } from '@kireidy/services';

@Component({
    selector: 'app-features',
    templateUrl: './features.component.html',
    styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent implements OnInit {

    public host?: Host;

    constructor(
        public onlineService: OnlineService,
        private overlayService: OverlayService,
        private router: Router) { }

    ngOnInit(): void {

        this.onlineService.getHost().subscribe({
            next: (host: Host) => {
                this.host = host;
            }
        })
    }

    public get isFullscreen() {
        return document.fullscreenElement != null;
    }

    public enterFullscreen() {
        document.documentElement.requestFullscreen();
    }

    public exitFullscreenCancel() {
        document.exitFullscreen();
    }

    public toggleFullscreen() {

        if (this.isFullscreen) {
            this.exitFullscreenCancel();
        } else {
            this.enterFullscreen();
        }
    }

    public logout(): void {

        this.onlineService.logout();

        this.router.navigate(['login']);
    }

}
