import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LOCATION_INITIALIZED } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpBackend, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { firstValueFrom } from 'rxjs';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';

import { JsonDateInterceptor, JwtInterceptor } from '@kireidy/services';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from './shared/shared.module';
import { LoginModule } from './login/login.module';
import { FeaturesModule } from './features/features.module';

// Multiple
export const httpLoaderFactory = (http: HttpBackend): MultiTranslateHttpLoader => {
    return new MultiTranslateHttpLoader(http, [
        'assets/i18n/core/',
        'assets/i18n/shared/',
        'assets/i18n/http/',
    ]);
};

const DefaultLanguage = 'fr';

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return async () => {

        await injector.get(LOCATION_INITIALIZED, Promise.resolve());

        translate.setDefaultLang(DefaultLanguage);

        await firstValueFrom(translate.use(DefaultLanguage));
    }
}

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpBackend],
            },
        }),
        SharedModule,
        LoginModule,
        FeaturesModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {   provide: HTTP_INTERCEPTORS,
            useClass: JsonDateInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class AppModule { }
